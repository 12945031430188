import { createRouter, createWebHashHistory } from 'vue-router';
export const staticRoutes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index.vue'),
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
  // }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes: staticRoutes,
  scrollBehavior: (to, from) => {
    if (to.path !== from.path) {
      setTimeout(() => {
        document.getElementById('app').scrollTop = 0;
      });
    }
    return {
      top: 0,
    };
  },
});
export default router;