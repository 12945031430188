import App from './App.vue'
import { createApp } from 'vue';
import router from './router';
import { Button,Toast,Form, Field, CellGroup,Uploader } from 'vant';
import 'vant/lib/index.css';
const app = createApp(App);
app
.use(Button)
.use(Toast)
.use(Form)
.use(Field)
.use(CellGroup)
.use(Uploader)
.use(router)
.mount('#app');